<template>
    <div class="share-calculator">
        <p>{{ translate('share_calculator_current_situation_1') }} <input type="number" class="form-control form-control-in-text" min="0" :max="originalTotalShares" v-model="amountOfShares"> <span v-html="translate('share_calculator_current_situation_2', [{ key: 'SHARE_VALUE', value: $options.filters.money(originalShareValue) }, { key: 'TOTAL_SHARE_VALUE', value: $options.filters.money(amountOfShares * originalShareValue) }])"></span></p>
        <p v-html="translate('share_calculator_new_situation_1', [{ key: 'PERCENTAGE_VALUE', value: $options.filters.percentage(amountOfShares / originalTotalShares) }])"></p>
        <p v-html="translate('share_calculator_new_situation_2', [{ key: 'SHARE_PRICE', value: $options.filters.money(newShareValue) }, { key: 'PERCENTAGE_INCREASE', value: $options.filters.percentage((newShareValue / originalShareValue * 100 - 100) / 100) }, { key: 'TOTAL_SHARE_VALUE', value: $options.filters.money(amountOfShares * newShareValue) }])"></p>
        <p v-html="translate('share_calculator_new_situation_3', [{ key: 'SHARE_AMOUNT', value: $options.filters.money(addedShares) }])"></p>
        <p v-html="translate('share_calculator_new_situation_4', [{ key: 'SHARE_AMOUNT', value: $options.filters.money(amountOfShares) }, { key: 'SHARE_PERCENTAGE', value: $options.filters.percentage(amountOfShares / newTotalShares) }, { key: 'AVAILABLE_SHARES', value: $options.filters.money(Math.ceil((amountOfShares * newTotalShares) / originalTotalShares - amountOfShares)) }])"></p>
    </div>
</template>

<script>
export default {
    props: {
        initialAmountOfShares: {
            type: Number,
            required: false,
            default: 10
        },
        originalShareValue: {
            type: Number,
            required: false,
            default: 300
        },
        newShareValue: {
            type: Number,
            required: false,
            default: 500
        },
        originalTotalShares: {
            type: Number,
            required: false,
            default: 56067
        },
        addedShares: {
            type: Number,
            required: false,
            default: 3000
        }
    },

    data () {
        return {
            amountOfShares: 10
        }
    },

    computed: {
        newTotalShares () {
            return this.originalTotalShares + this.addedShares
        }
    },

    watch: {
        amountOfShares (amountOfShares) {
            if (amountOfShares < 0) {
                this.amountOfShares = 0
            }

            if (amountOfShares > this.originalTotalShares) {
                this.amountOfShares = this.originalTotalShares
            }
        }
    },

    created () {
        this.amountOfShares = this.initialAmountOfShares
    },

    filters: {
        percentage (value) {
            return new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 4 }
            ).format(value * 100)
        },

        money (value) {
            return new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
            ).format(value)
        }
    }
}
</script>
