<template>
    <form method="POST" :action="twoFactorLoginRoute" @submit.prevent="login" class="text-center">

        <div class="login-content__block__title">{{ translate('two-factor-auth.two_factor_authentication') }}</div>

        <div v-if="otpEmailRecipient" class="user-data__message user-data__message--success" style="margin: 30px 0;">
            {{ translate('two-factor-auth.email_sent_to', [{'key': 'email', 'value':otpEmailRecipient}]) }}
        </div>

        <div>
            <div class="login-content__block__description mb-4" style="font-size: 14px;">
                {{ translate('two-factor-auth.confirm_otp') }}
                {{ translate('two-factor-auth.or_recovery_code') }}
            </div>
            <div class="mb-4 two-factor-code">
                <label class="w-100" for="code" :value="translate('two-factor-auth.otp_code')">
                    <div class="dg-input-wrapper dg-text-input-wrapper has-icon text-left mt-0" :class="{'has-error': form.errors.has('code')}">
                        <input v-model="form.code" type="text" name="code" :placeholder="translate('two-factor-auth.otp_code') + ' *'" inputmode="numeric" autofocus autocomplete="one-time-code">
                        <div class="dg-input-label">
                            {{ translate('two-factor-auth.otp_code') }}
                            <span class="dg-input-required">*</span>
                        </div>
                        <i class="icon-lock"></i>
                    </div>
                    <div v-if="form.errors.has('code')" class="dg-input-error has-icon mt-2">{{ form.errors.get('code') }}</div>
                    <div v-if="form.errors.has('recovery_code')" class="dg-input-error has-icon mt-2">{{ form.errors.get('recovery_code') }}</div>
                </label>
            </div>
        </div>

        <button type="submit" class="mb-2 mt-0 dg-button color-primary">{{ translate('two-factor-auth.login') }}</button>
        <div class="mt-4">
            <a @click.prevent="sendOtpCode" :href="twoFactorEmailRoute" style="font-size: 12px; text-decoration: underline;">{{  translate('two-factor-auth.lost_device') }} {{ translate('two-factor-auth.send_otp_to_email') }}</a>
        </div>
    </form>

</template>

<script>
import xhr from '../../core/xhr';

export default {
    props: {
        twoFactorLoginRoute: {
            type: String,
            required: true
        },
        twoFactorEmailRoute: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            recovery: false,
            otpEmailRecipient: null,
            form: new xhr({
                code: '',
                emailCode: null,
            }),
        }
    },
    methods: {
        async login() {
            this.error = false;
            this.loading = true;

            let response = null

            try {
                response = await this.form.post(this.twoFactorLoginRoute);
            } catch (error) {
                this.loading = false;
                console.log(this.form.errors);
            }


            if (response && response.success) {
                this.$emit('loginSuccess', response);
            }
        },
        async sendOtpCode() {
            let request = new xhr();
            let response = null;

            try {
                response = await request.get(this.twoFactorEmailRoute);
            } catch (error) {
                console.error(error);
                window.location.reload();
            }

            if (response && response.success) {
                this.form.emailCode = 'true';
                this.otpEmailRecipient = response.recipient
                setTimeout(() => this.form.emailCode = null, 240000)
            }
        }
    },
}
</script>
