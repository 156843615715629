<template>
    <div id="rangeSelector">
        <div class="row mb-2">
            <div class="col-12">
                {{ translate('investment_sum_in_project') }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6 col-lg-4">
                <input type="text" ref="nice" class="rangeCustomInput" v-model="inputValue" @focus="onFocus" @blur="onBlur" @input="onInput" />
            </div>
            <div class="col-6 offset-lg-3 col-lg-5" v-if="showExchangeRateCalculator">
                <div class="dg-input-wrapper dg-select-input-wrapper">
                    <select class="rangeCustomInput no-shadow input-small" v-model="exchangeRate" @change="onBlur">
                        <option v-for="rate in exchangeRates.rates" :value="rate" :key="rate.name">{{ rate.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-4" v-if="exchangeRate.name != 'EUR'">
            <div class="col-12">
                <span class="rangeCustomInput d-inline-block w-auto px-3">{{ exchangeValue }}</span><sup> *1</sup>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <input class="customRangeSelector" type="range" id="selector" :min="min" :max="max" v-model="selectedValue" @input="onChange" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                {{ translate('expected_payback_amount') }}
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6 col-lg-4">
                <input type="text" class="rangeCustomInput" disabled="disabled" :value="calculatedValue | numberFormat" />
            </div>
            <div class="col-6 col-lg-8">
                {{ translate('interest_rate') }}: <span v-html="translate('interest_per_year_alt', [{ key: 'INTEREST_VALUE', value: $options.filters.interestNumberFormat(interest) }])"></span>
            </div>
        </div>
        <div class="row mb-4" v-if="exchangeRate.name != 'EUR'">
            <div class="col-12">
                <span class="rangeCustomInput d-inline-block w-auto px-3">{{ calculatedExchangeValue }}</span><sup> *1</sup>
            </div>
        </div>
        <div :class="{'row': true, 'mb-4': exchangeRate.name != 'EUR'}">
            <div class="col-12">
                {{ translate('interest_begin') }}
            </div>
        </div>
        <div class="row" v-if="exchangeRate.name != 'EUR'">
            <div class="col-12">
                <small>
                    <sup>*1 </sup>{{  translate('exchange_rate_info', [{key: 'EXCHANGE_DATE', value: exchangeRates.time}]) }}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            min: {
                type: String,
                default: "250"
            },
            max: {
                type: String,
                default: "30000"
            },
            interest: String,
            duration: String,
            start: String,
            falligkeit: String,
            exchangeRates: {
                type: Object,
                default: () => [
                    {
                        time: null,
                        rates: [
                            {name: 'EUR', rate: 1},
                        ]
                    }
                ]
            },
            showExchangeRateCalculator: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            calculatedValue() {
                if(Number(this.falligkeit) > 1) {
                    let inter = Number(this.interest) / 100;
                    let monthlyInterest = (Number(this.selectedValue) * inter) / 12;
                    var result = Number(this.selectedValue) + (monthlyInterest * Number(this.duration));
                } else if(Number(this.duration) < 13) {
                    let inter = Number(this.interest) / 100;
                    let monthlyInterest = (Number(this.selectedValue) * inter) / 12;
                    var result = Number(this.selectedValue) + (monthlyInterest * Number(this.duration));
                } else {
                    let fullyears = parseInt(Number(this.duration)/12);

                    var result = Number(this.selectedValue) *
                                 Math.pow(Number(this.interest)/100 + 1, fullyears) +
                                 (
                                     Number(this.selectedValue) *
                                     Math.pow(Number(this.interest) / 100 + 1, fullyears) *
                                     Number(this.interest) / 100 / 12 *
                                     (Number(this.duration) - (fullyears * 12))
                                 );
                }

                return result.toFixed(0).toString();
            },
            exchangeValue() {
                let exchangeValue = parseFloat(this.selectedValue) * parseFloat(this.exchangeRate.rate);
                return this.exchangeRate.name + ' ' + new Intl.NumberFormat(
                    'de-DE',
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                ).format(exchangeValue.toFixed(2));
            },
            calculatedExchangeValue() {
                let exchangeValue = parseFloat(this.calculatedValue) * parseFloat(this.exchangeRate.rate);
                return this.exchangeRate.name + ' ' + new Intl.NumberFormat(
                    'de-DE',
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                ).format(exchangeValue.toFixed(2));
            }
        },

        filters: {
            numberFormat (value) {
                return "EUR " + value;
            },

            interestNumberFormat (value) {
                let interest = Number(value);
                return `${interest.toFixed(2)}%`.replace('.', ',');
            }
        },

        created() {
            this.inputValue = "EUR " + this.selectedValue;
        },

         methods: {
            onFocus () {
                this.inputValue = this.selectedValue;
            },

            onBlur () {
                if (typeof this.inputValue === 'string') {
                    this.inputValue = this.exchangeRates.rates.reduce((cur, rate) => cur.replace(rate.name, '').trim(), this.inputValue);
                }

                console.log(this.inputValue, this.min, parseFloat(this.inputValue) < parseFloat(this.min))
                if(parseFloat(this.inputValue) < parseFloat(this.min)) {
                    this.inputValue = this.min;
                }
                this.selectedValue = this.inputValue
                this.inputValue = "EUR " + this.inputValue;
            },

            onInput () {
                this.inputValue = Number(this.inputValue);
                if(Number.isNaN(this.inputValue)) {
                    this.inputValue = this.min;
                }
                this.selectedValue = this.inputValue
            },

            onChange() {
                this.inputValue = "EUR " + this.selectedValue;
            }
         },

        data () {
            return {
                exchangeRate: {name: 'EUR', rate: 1},
                selectedValue: this.start,
                inputValue: this.start,
            }
        }
    }
</script>
