<template>
    <div class="row" id="financialStructure">
        <div class="col-12 text-center">
            <div class="donutChart">
                <div class="portionBlock" v-for="item in parts" :key="item.name" :style="{ transform: item.start }">
                    <div class="circle" :style="{ transform: item.dag, background: item.color }"></div>
                </div>
                <div class="center"></div>
            </div>
        </div>
        <div class="col-12 text-left mt-4">
            <div class="legendPart" v-for="item in parts" :key="item.name">
                <div class="color" :style="{ background: item.color }"></div>
                <div class="text">{{item.name}}: {{item.value}}%</div>
            </div>
        </div>
    </div>
    
</template>
<script>
    export default {
        props: {
            values: Array,
            gap: {
                type: String,
                default: "3"
            }
        },

        created() {
            let start = 0;
            for(let i = 0; i < this.values.length; i++) {
                let tmp = {
                    name: this.values[i].name, 
                    value: this.values[i].value, 
                    color: "#"+this.values[i].color,
                    start: 'rotate(' + start + 'deg)',
                    dag: 'rotate(' + (360 * (parseInt(this.values[i].value) / 100) - parseInt(this.gap)) + 'deg)'
                };
                this.parts.push(tmp);
                start = start + 360 * (parseInt(this.values[i].value) / 100);
            }
        },

        data() {
            return {
                parts: []
            };
        }
    }
</script>