<template>
  <span>EUR {{ serviceFee | money }}</span>
</template>

<script>
export default {
  props: {
    initialServiceFee: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data () {
    return {
      serviceFee: 0
    }
  },

  created () {
    this.serviceFee = this.initialServiceFee

    App.dispatcher.listen('serviceFeeChange', response => {
      this.serviceFee = parseFloat(response.serviceFee)
    })
  },

  filters: {
    money (value) {
      return new Intl.NumberFormat(
        'de-DE',
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      ).format(value)
    }
  }
}
</script>
