<template>
    <div class="row">
        <div class="col-12 col-md-10 mb-3">
            <b>{{ translate('reference_account_missing') }}<br>{{ translate('reference_account_enter') }}</b>
            <div class="form-group row mt-4 mb-0">
                <label for="iban" class="col-sm-4 col-form-label">{{ translate('iban') }} <span class="text-blue">*</span></label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="iban" name="iban" autocomplete="off" v-model="iban" @keyup="onInput">
                    <span v-if="erroriban != ''" class="invalid-feedback" role="alert">
                        <strong>{{ erroriban }}</strong>
                    </span>
                </div>
                <div class="ibanValidation" id="validation"></div>
            </div>
            <div class="form-group row mt-4">
                <label for="bic" class="col-sm-4 col-form-label">{{ translate('bic') }} <span class="text-blue">*</span></label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="bic" name="bic" autocomplete="off" v-model="bic">
                    <span v-if="errorbic != ''" class="invalid-feedback" role="alert">
                        <strong>{{ errorbic }}</strong>
                    </span>
                </div>
            </div>
            <button v-if="notSaved" type="button" id="submitButton" class="btn btn-secondary" @click="saveIban" style="display: none;">{{ translate('reference_account_save') }}</button>
            <div v-else>
                <b class="text-green">{{ translate('saved') }}</b>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../core/xhr';
    import Vue from 'vue'

    export default {

         methods: {
            onInput() {
                let iban = this.iban;
                iban = iban.replace(/\s/g,'');
                if(iban.length > 14) {
                    document.getElementById("validation").classList.add("loader");
                    let bic = "";
                    if(iban != '') {
                        $.getJSON('https://openiban.com/validate/'+iban+'?getBIC=true&validateBankCode=true', function(data) {
                            document.getElementById("validation").classList.remove("loader");
                            if(data.valid == true) {
                                document.getElementById("validation").classList.remove("error");
                                document.getElementById("validation").classList.add("success");

                                if(typeof data.bankData.bic !== 'undefined') {
                                    if(data.bankData.bic == "EASYATW1XXX")
                                    {
                                        this.bic = "BAWAATWW";
                                    } else {
                                        this.bic = data.bankData.bic;
                                    }

                                }
                                document.getElementById('submitButton').style.display = "inline-block";
                            } else {
                                document.getElementById("validation").classList.remove("success");
                                document.getElementById("validation").classList.add("error");
                                this.bic =  '';
                                document.getElementById('submitButton').style.display = "none";
                            }
                        }.bind(this));

                    } else {
                        document.getElementById("validation").classList.remove("loader");
                        document.getElementById("validation").classList.remove("success");
                        document.getElementById("validation").classList.remove("error");
                    }
                } else {
                    document.getElementById("validation").classList.add("error");
                }
                    iban = iban.toUpperCase();
                    this.iban = iban.replace(/(.{4})/g, '$1 ').trim();

            },

            saveIban () {
                this.erroriban = "";
                this.errorbic = "";
                new Xhr({iban: this.iban, bic: this.bic}).post('/backend/profildaten/bankData/enterIban').then((response) => {
                    if(document.getElementById('paymentType2') !== null) {
                        if(document.getElementById('paymentType2').checked == true) {
                            $('#sepaCheckbox').collapse('show');
                            $('#sepaFile').collapse('show');
                        }
                    }
                    if(document.getElementById('btnContainer') !== null) {
                        document.getElementById('btnContainer').style.display = 'block';
                    }
                    if(document.getElementById('ibanNotEntered') !== null) {
                        document.getElementById('ibanNotEntered').style.display = 'none';
                    }
                    this.notSaved = false;
                }).catch (error => {
                    if(error.errors.hasOwnProperty("iban"))
                        this.erroriban = error.errors.iban[0];
                    if(error.errors.hasOwnProperty("bic"))
                        this.errorbic = error.errors.bic[0];
                })
            },
        },


        data () {
            return {
                iban: null,
                bic: null,
                erroriban: '',
                errorbic: '',
                notSaved: true
            }
        }
    }
</script>
