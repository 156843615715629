<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-6"><h3>Transactions</h3></div>
                    <div class="col-6 text-right"><button class="btn btn-primary" v-on:click="downloadCSV()">CSV Download</button></div>
                </div>
                <table class="w-100 borders">
                    <tr>
                        <td><b>Date/Time</b></td>
                        <td><b>ID</b></td>
                        <td><b>Name</b></td>
                        <td><b>Description</b></td>
                        <td><b>Method</b></td>
                        <td><b>Amount</b></td>
                        <td><b>Reference</b></td>
                        <td><b>Refunded</b></td>
                        <td><b>Total</b></td>
                        <td><b>Status</b></td>
                        <td><b>Actions</b></td>
                    </tr>
                    <tr v-for="transaction, index in transactionsLimited" :key="transaction.uid">
                        <td>{{ transaction.created | date }}</td>
                        <td>{{ transaction.uid }}</td>
                        <td>{{ transaction.order.customer.name_first }} {{ transaction.order.customer.name_last }}</td>
                        <td>{{ transaction.order.products[0].name }}</td>
                        <td>{{ transaction.payment_method }}</td>
                        <td>{{ (transaction.amount/100) | money }}</td>
                        <td v-if="transaction.payment_details">{{ transaction.payment_details.reference }}</td><td v-else></td>
                        <td>
                            <div v-for="refund in transaction.refunds" :key="refund.uid">
                                {{ refund.created | date}} - {{ (refund.amount/100) | money}}
                            </div>
                        </td>
                        <td>{{ totalValue(index) | money }}</td>
                        <td>{{ transaction.status }}<span v-if="transaction.status == 'chargeback' && transaction.payment_details"><br>{{ transaction.payment_details.message }}</span></td>
                        <td>
                            <div v-if="totalValue(index) > 0 && transaction.status != 'cancelled'">
                                <div v-if="(transaction.status == 'created' || transaction.status == 'pending') && transaction.payment_method == 'sepa'">
                                    <button class="btn btn-primary" v-on:click="cancelTransaction(index)">Cancel/Delete</button>
                                </div>
                                <div v-else-if="transaction.status == 'completed' || transaction.status == 'refunded' || transaction.status == 'reserved'">
                                    <button class="btn btn-primary" v-on:click="refundTransaction(index)">Refund</button>
                                    <input type="number" name="value" :id="'refundInput'+index" placeholder="Partial refund (Value)" v-on:keyup="maxValue(index)" v-on:change="maxValue(index)" />
                                </div>
                            </div>
                            <div v-else>
                                Full refund / Canceled
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-12  mt-3">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                    <li class="page-item"><a class="page-link" v-on:click="swichPage(current_page - 1)">&laquo;</a></li>
                    <li class="page-item" :class="{ active: i == current_page }" v-for="i in lastPage" :key="i"><a class="page-link" v-on:click="swichPage(i)">{{i}}</a></li>
                    <li class="page-item"><a class="page-link"  v-on:click="swichPage(current_page + 1)">&raquo;</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h2 class="text-center mb-5">Money transfers between OPP accounts</h2>
            </div>
            <div class="col-4">
                <h2 class="text-center mb-3">Project</h2>
                <b>Investments:</b> {{ totalInvestments | money }}<br>
                <b>DIS amount:</b> {{ disamount | money }}<br>
                <b>Cancelled:</b> {{ totalCancelled | money }}<br>
                <b>Refunds:</b> {{ totalRefunds | money }}<br>
                <b>Sum:</b> {{ (totalInvestments - totalCancelled - totalRefunds) | money }}<br>
                <br>
                <b>Website:</b> {{ projectinvested | money }}<br>
            </div>
            <div class="col-4">
                <h2 class="text-center mb-3">Costs</h2>
                <b>Confirmed:</b> {{ totalCompleted | money }}<br>
                <b>Transfered from other projects:</b> {{ (transferedfromotherprojects / 100) | money }}<br>
                <b>Charged:</b> {{ ((dagoservicecharge / 100) + (transferedtootherprojects / 100) + (dagocharge / 100) + (dagoagcharge / 100) + (projectholdercharge / 100)) | money }}<br>
                <b>Total available:</b> {{ (totalCompleted + (transferedfromotherprojects / 100) - (transferedtootherprojects / 100) - (dagoservicecharge / 100) - (dagocharge / 100) - (dagoagcharge / 100)  - (projectholdercharge / 100)) | money }}<br>
                <br>
                <b>Mandate pending:</b> {{ totalReserved | money }}<br>
                <b>Transfers pending:</b> {{ totalPending | money }}<br>
                <br>
                <b>Available + Pendings:</b> {{ ((totalCompleted + (transferedfromotherprojects / 100) - (transferedtootherprojects / 100) - (dagoservicecharge / 100) - (dagocharge / 100) - (dagoagcharge / 100)  - (projectholdercharge / 100)) + totalReserved + totalPending ) | money }}<br>
            </div>
            <div class="col-4">
                <h2 class="text-center mb-3">Transfers</h2>
                <b>DIS transfers:</b> {{ (dagoservicecharge / 100) | money }}<br>
                <b>DIV transfers:</b> {{ (dagocharge / 100) | money }}<br>
                <b>DAG transfers:</b> {{ (dagoagcharge / 100) | money }}<br>
                <b>Project holder transfers:</b> {{ (projectholdercharge / 100) | money }}<br>
                <b>Other transfers:</b> {{ (transferedtootherprojects / 100) | money }}<br>
                <br>
                <b>Total:</b> {{ ((dagoservicecharge / 100) + (dagocharge / 100) + (dagoagcharge / 100) + (projectholdercharge / 100) + (transferedtootherprojects / 100)) | money }}<br>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../../core/xhr';
    import Vue from 'vue'

    export default {
        props: {
            merchant: String,
            dagoservicecharge: Number,
            dagocharge: Number,
            dagoagcharge: Number,
            projectholdercharge: Number,
            projectinvested: Number,
            transferedfromotherprojects: Number,
            transferedtootherprojects: Number,
            disamount: Number,
        },

        created() {
            new Xhr({uid: this.merchant}).post('/dagoadmin/opp/get-transactions').then((response) => {
                this.transactions = response;

                this.lastPage = Number(this.transactions.total_item_count) / this.perPage;

                if(this.lastPage != parseInt(this.lastPage)) {
                    this.lastPage =  parseInt(this.lastPage) + 1;
                }

                this.swichPage(1);
            })
        },

        filters: {
            money(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value));
            },

            date(value) {
                let dateFormat= new Date(value * 1000);

                return dateFormat.toLocaleDateString('de-DE', {}) + " " + dateFormat.toLocaleTimeString('de-DE', {});
            }
        },

        computed: {
            totalInvestments() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    total = total + this.transactions.data[i].amount;
                }
                return total / 100;
            },

            totalRefunds() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    let refunds = this.transactions.data[i].refunds;
                    for(let i = 0; i < refunds.length; i++) {
                        total = total + refunds[i].amount;
                    }
                }
                return total / 100;
            },

            totalCancelled() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    if(this.transactions.data[i].status == 'cancelled' || this.transactions.data[i].status == 'expired' || this.transactions.data[i].status == 'failed') {
                        total = total + this.transactions.data[i].amount;
                    }
                }
                return total / 100;
            },

            totalCompleted() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    if(this.transactions.data[i].status == 'completed') {
                        total = total + this.transactions.data[i].amount;
                    } else if(this.transactions.data[i].status == 'refunded') {
                        let amount = this.transactions.data[i].amount;
                        let refunds = this.transactions.data[i].refunds;

                        for(let j = 0; j < refunds.length; j++) {
                            amount = amount - refunds[j].amount;
                        }

                        total = total + amount;
                    }
                }
                return total / 100;
            },

            totalReserved() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    if(this.transactions.data[i].status == 'reserved') {
                        total = total + this.transactions.data[i].amount;
                    }
                }
                return total / 100;
            },

            totalPending() {
                let total = 0;
                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    if(this.transactions.data[i].status == 'pending') {
                        total = total + this.transactions.data[i].amount;
                    }
                }
                return total / 100;
            },

            onAccount() {
                return this.totalInvestments - 
                       this.totalRefunds - 
                       this.totalCancelled - 
                       (this.dagoservicecharge / 100) - 
                       (this.dagocharge / 100) - 
                       (this.dagoagcharge / 100) - 
                       (this.projectholdercharge / 100);
            }
        },

        methods: {
            downloadCSV() {
                let rows = [
                    ["Date/Time", "ID", "Name", "Description", "Method", "Amount", "Reference", "Status"]
                ];

                for(let i = 0; i < this.transactions.total_item_count; i++) {
                    rows.push([
                        this.$options.filters.date(this.transactions.data[i].created), 
                        this.transactions.data[i].uid, 
                        this.transactions.data[i].order.customer.name_first + ' ' + this.transactions.data[i].order.customer.name_last,
                        this.transactions.data[i].order.products[0].name,
                        this.transactions.data[i].payment_method,
                        this.$options.filters.money(this.transactions.data[i].amount / 100),
                        this.transactions.data[i].payment_details ? this.transactions.data[i].payment_details.reference : '',
                        this.transactions.data[i].status
                    ])
                }

                let csvContent = "data:text/csv;charset=utf-8," 
                    + "\uFEFF" + rows.map(e => e.map(String).map(v => v.replaceAll('"', '""')).map(v => `"${v}"`).join(";")).join("\n");

                var encodedUri = encodeURI(csvContent);
                window.open(encodedUri);
            },

            swichPage(page) {
                if(page < 1) {
                    page = 1;
                }
                if(page > this.lastPage) {
                    page = this.lastPage;
                }
                let start = (page - 1) * this.perPage;
                this.transactionsLimited = this.transactions.data.slice(start, page*this.perPage);
                this.current_page = page;
            },

            totalValue(index) {
                let amount = this.transactionsLimited[index].amount;
                let refunds = this.transactionsLimited[index].refunds;

                for(let i = 0; i < refunds.length; i++) {
                    amount = amount - refunds[i].amount;
                }

                return amount/100;
            },

            cancelTransaction(index) {
                new Xhr({tid: this.transactionsLimited[index].uid}).post('/dagoadmin/opp/cancel').then((response) => {
                    if(response) {
                        this.transactionsLimited[index].status = 'cancelled';
                    }
                })
            },

            refundTransaction(index) {
                let inputValue = document.getElementById('refundInput'+index).value;
                if(inputValue != '') {
                    let value = inputValue
                    document.getElementById('refundInput'+index).value = '';
                    new Xhr({tid: this.transactionsLimited[index].uid, value: value}).post('/dagoadmin/opp/refund').then((response) => {
                        console.log(response);
                        let refunds = this.transactionsLimited[index].refunds;
                        Vue.set(this.transactionsLimited[index].refunds, refunds.length, response);             
                    });
                }
            },

            maxValue(index) {
                let inputValue = document.getElementById('refundInput'+index).value;
                let total = this.totalValue(index);

                if(inputValue > total) {
                    document.getElementById('refundInput'+index).value = total;
                }
            }
        },
        data() {
            return {
                transactions: [],
                transactionsLimited: [],
                current_page: 1,
                perPage: 20,
                lastPage: 1,
            };
        }
    }
</script>