<template>
    <svg class="projectProgress" :width="radius * 2" :height="radius * 2">
        <circle stroke="#E1E1E1" stroke-width="1" fill="transparent" :r="normalizedRadius" :cx="radius" :cy="radius" />
        <circle
            :stroke-dasharray="circumferenceFill + ' ' + circumferenceFill" 
            :style="{ strokeDashoffset: strokeDashoffsetFill }" 
            :r="normalizedRadiusFill" 
            :cx="radius" 
            :cy="radius"
            fill="transparent"
            stroke="#E1E1E1"
            :stroke-width="radius"
        />
        <circle stroke="#FFF" :stroke-width="parseInt(stroke)*2" fill="transparent" :r="parseInt(normalizedRadius)+parseInt(stroke)" :cx="radius" :cy="radius" />
        <circle 
            :stroke-dasharray="circumferenceOuter + ' ' + circumferenceOuter" 
            :style="{ strokeDashoffset: strokeDashoffsetOuter }" 
            stroke="#FAC000" 
            :stroke-width="stroke" 
            fill="transparent" 
            :r="normalizedRadiusOuter" 
            :cx="radius" 
            :cy="radius"
        />
        <circle 
            :stroke-dasharray="circumference + ' ' + circumference" 
            :style="{ strokeDashoffset: strokeDashoffset }" 
            stroke="#004460" 
            :stroke-width="stroke" 
            fill="transparent" 
            :r="normalizedRadius" 
            :cx="radius" 
            :cy="radius"
        />
        <text class="investmentsText" :font-size="fontsize" :x="radius" :y="parseInt(radius) + parseInt(stroke / 2)" dominant-baseline="middle" text-anchor="middle">{{value1}}%</text>
        <rect class="startFinishLine" :x="parseInt(radius) - 1" :y="parseInt(stroke / 2)" width="2" height="30" />
    </svg>
</template>

<script>
    export default {
        props: {
            radius: {
                type: String,
                default: "85"
            },
            stroke: {
                type: String,
                default: "10"
            },
            fontsize: {
                type: String,
                default: "34"
            },
            value1: String,
            value2: String
        },
        computed: {
            strokeDashoffset() {
                let prog = this.value1 > 100 ? 100 : this.value1;
                return this.circumference - parseInt(prog) / 100 * this.circumference;
            },

            strokeDashoffsetFill() {
                let prog = this.value1 > 100 ? 100 : this.value1;
                return (this.circumferenceFill - parseInt(prog) / 100 * this.circumferenceFill);
            },

            strokeDashoffsetOuter() {
                let prog = this.value2 < 0 ? 0 : this.value2;
                return this.circumferenceOuter - parseInt(prog) / 100 * this.circumferenceOuter;
            }
        },
        data() {
            const normalizedRadius = parseInt(this.radius) - parseInt(this.stroke) * 2;
            const circumference = normalizedRadius * 2 * Math.PI;

            const normalizedRadiusFill = (parseInt(this.radius) / 2);
            const circumferenceFill = normalizedRadiusFill * 2 * Math.PI;

            const normalizedRadiusOuter = parseInt(this.radius) - parseInt(this.stroke);
            const circumferenceOuter = normalizedRadiusOuter * 2 * Math.PI;

            return {
                normalizedRadius,
                circumference,
                normalizedRadiusFill,
                circumferenceFill,
                normalizedRadiusOuter,
                circumferenceOuter
            };
        }
    }
</script>
<style>
    circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
</style>
