<template>
    <div>
        <div class="row" v-if="forms.length">
            <div class="col">
                {{ translate('select_form') }}:
                <select v-model="selectedForm" :disabled="shouldDisableActions">
                    <option v-for="form in forms" :key="form">
                        {{ form }}
                    </option>
                </select>
            </div>
        </div>
        <div v-else>
            {{ translate('dag_investment_form_submits_no_forms') }}
        </div>

        <div class="mt-5" v-if="formSubmits.length">
            <div class="actions d-flex">
                <div>
                    <label :class="{ 'disabled': shouldDisableActions }">
                        <input type="checkbox" :value="formSubmits.length - 1" v-model="markedSubmits" :disabled="shouldDisableActions">
                        {{ translate('mark_all') }}
                    </label>
                </div>
                <div class="ml-4" v-if="markedSubmits.length">
                    <a class="btn btn-primary" :href="csvDownloadUrl" :class="{ 'disabled': shouldDisableActions }">
                        {{ translate('export_csv') }}
                    </a>
                    <button class="btn btn-danger" @click="deleteFormSubmits" :disabled="shouldDisableActions">
                        {{ translate('delete') }}
                    </button>
                </div>
            </div>
            <div class="table-wrap mt-2">
                <table class="table table-bordered">
                    <tr>
                        <th>
                            {{ translate('mark') }}
                        </th>
                        <th v-for="(column, i) in formSubmits[0].data" :key="i">
                            {{ column[0] }}
                        </th>
                    </tr>
                    <tr v-for="(submit, i) in formSubmits" :key="i" :class="{ 'is-marked': markedSubmits.includes(i) }">
                        <td class="text-center">
                            <input type="checkbox" :value="i" v-model="markedSubmits" :disabled="shouldDisableActions">
                        </td>
                        <td v-for="(column, j) in submit.data" :key="j" :class="{ 'is-editable': !shouldDisableActions }">
                            <input type="text" ref="editedField" v-if="isEditingField(i, j)" v-model="formSubmits[i].data[j][1]" @keyup.enter="stopEditingAndUpdate" @keyup.escape="stopEditingAndCancel" v-click-outside="stopEditingAndUpdate">
                            <span v-else>
                                {{ column[1] }}
                                <span class="edit-field" @click.stop="editField(i, j)" v-if="!shouldDisableActions">
                                    <span class="sr-only">{{ translate('edit') }}</span>
                                </span>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

let controller;

export default {
    props: {
        initialForms: {
            type: Array,
            required: true,
            default: []
        }
    },

    data () {
        return {
            forms: [],
            selectedForm: null,
            formSubmits: [],
            markedSubmits: [],
            editedField: null,
            oldFieldValue: null,
            isFetchingFormSubmits: false,
            isUpdatingOrDeletingFormSubmits: false
        }
    },

    computed: {
        isEditingAnyField () {
            return this.editedField !== null;
        },

        shouldDisableActions () {
            return this.isEditingAnyField || this.isUpdatingOrDeletingFormSubmits;
        },

        csvDownloadUrl () {
            const submitIds = [];

            for (const submit of this.formSubmits) {
                submitIds.push(submit.id);
            }

            return `/dagoadmin/export-investment-form-submit-csv?submits=${submitIds.join(',')}`;
        }
    },

    created () {
        this.forms = this.initialForms;
        this.selectedForm = this.forms[0];
    },

    watch: {
        selectedForm (selectedForm) {
            this.fetchFormSubmits(selectedForm);
        }
    },

    methods: {
        fetchFormSubmits (form) {
            const apiUrl = `/dagoadmin/get-dag-investment-form-submits?formName=${form}`;

            controller = new AbortController();

            this.formSubmits = [];
            this.markedSubmits = [];
            this.isFetchingFormSubmits = true;

            axios.get(apiUrl, { signal: controller.signal })
                .then(response => {
                    for (const formSubmit of response.data) {
                        this.formSubmits.push({
                            id: formSubmit.id,
                            data: Object.entries(JSON.parse(formSubmit.data))
                        });
                    }

                    // If there are no form submits (e.g., because we just
                    // delete the last one) we remove it from the list of
                    // selectable forms
                    if (!this.formSubmits.length) {
                        this.forms = this.forms.filter(form => form !== this.selectedForm);
                        this.selectedForm = null;
                    }
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                    }
                })
                .finally(() => {
                    this.isFetchingFormSubmits = false;
                });
        },

        editField (i, j) {
            this.oldFieldValue = this.formSubmits[i].data[j][1];
            this.editedField = [i, j];

            this.$nextTick(() => {
                this.$refs.editedField[0].focus();
            });
        },

        isEditingField (i, j) {
            return this.isEditingAnyField &&
                this.editedField[0] === i &&
                this.editedField[1] === j;
        },

        stopEditingAndUpdate () {
            const formSubmitIndex = this.editedField[0];
            const oldFieldValue = this.oldFieldValue;
            const editedFieldValue = this.formSubmits[this.editedField[0]].data[this.editedField[1]][1];

            this.oldFieldValue = null;
            this.editedField = null;

            if (oldFieldValue !== editedFieldValue) {
                this.updateFormSubmit(this.formSubmits[formSubmitIndex]);
            }
        },

        stopEditingAndCancel () {
            this.formSubmits[this.editedField[0]].data[this.editedField[1]][1] = this.oldFieldValue;

            this.oldFieldValue = null;
            this.editedField = null;
        },

        updateFormSubmit (formSubmit) {
            const apiUrl = '/dagoadmin/update-dag-investment-form-submit';

            controller = new AbortController();

            this.isUpdatingOrDeletingFormSubmits = true;

            axios.post(apiUrl, {
                id: formSubmit.id,
                data: formSubmit.data
            }, { signal: controller.signal })
                .then(response => {
                    // Nothing to do?
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                        window.alert(this.translate('dag_investment_form_submits_update_error'));
                    }
                })
                .finally(() => {
                    this.isUpdatingOrDeletingFormSubmits = false;
                });
        },

        deleteFormSubmits () {
            if (!window.confirm(this.translate('dag_investment_form_submits_confirm_delete'))) {
                return;
            }

            const submitIds = [];

            for (const submit of this.formSubmits) {
                submitIds.push(submit.id);
            }

            const apiUrl = '/dagoadmin/delete-dag-investment-form-submits';

            controller = new AbortController();

            this.isUpdatingOrDeletingFormSubmits = true;

            axios.post(apiUrl, {
                ids: submitIds
            }, { signal: controller.signal })
                .then(response => {
                    // We re-fetch form submits to update the
                    this.fetchFormSubmits(this.selectedForm);
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                        window.alert(this.translate('dag_investment_form_submits_delete_error'));
                    }
                })
                .finally(() => {
                    this.isUpdatingOrDeletingFormSubmits = false;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

label {
    cursor: pointer;

    &.disabled {
        cursor: initial;
        pointer-events: none;
    }
}

.actions {
    line-height: 38px; // Matches the button height
}

table {
    tr {
        &.is-marked {
            background-color: $almostWhite;
        }

        th,
        td {
            white-space: nowrap;
        }

        td {
            position: relative;
            text-align: left;

            &.is-editable {
                @media (hover: hover) {
                    &:hover {
                        background-color: $lightest;
                    }
                }
            }

            .edit-field {
                cursor: pointer;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }
}
</style>
