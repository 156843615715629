<template>
    <div class="text-center">
        <div id="milestones">
            <div class="crowdFounding" :style="{ top: top, width: width }">{{ translate('crowd') }}</div>
            <div class="milestoneItemContainer" v-for="item in milestones" :key="item.name">
                <div class="milestoneItem" v-if="item.selected != '1'">{{ item.name }}</div>
                <div class="milestoneItem founded" v-if="item.selected == '1'">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            milestones: Array
        },

        computed: {
            top() {
                /* (number of skiped item * 50px) + 15px */
                let numberOfSkippedItems = 0;
                for(let i = 0; i < this.milestones.length; i++) {
                    if(this.milestones[i].selected == 1) {
                        numberOfSkippedItems = i;
                        break;
                    }
                }

                return ((numberOfSkippedItems * 50) + 15) + "px";
            },

            width() {
                /* (number of selected item - 1 item) *  50 */
                let numberOfSelectedItems = 0;
                for(let i = 0; i < this.milestones.length; i++) {
                    if(this.milestones[i].selected == 1) {
                        numberOfSelectedItems++;
                    }
                }

                return ((numberOfSelectedItems - 1) * 50) + "px";
            }
        }
    }
</script>
