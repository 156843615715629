<template>
    <div>
        <section class="closed-projects">
            <div v-for="closedProjectMarkup in closedProjectsMarkup" v-html="closedProjectMarkup"></div>
        </section>
        <div v-inview:enter="() => update()"></div>
        <div class="spinner" v-if="isUpdating"></div>
    </div>
</template>

<script>
import axios from 'axios';

let controller;

export default {
    data () {
        return {
            closedProjectsMarkup: [],
            fullyLoaded: false,
            isUpdating: false,
            page: 1
        };
    },

    methods: {
        update (filters) {
            if (this.fullyLoaded) {
                return;
            }

            if (this.isUpdating) {
                this.page--;
                controller.abort(
                    'Aborting previous request due to filter change.'
                );
            }

            this.page++;
            this.isUpdating = true;

            let apiUrl = '/api/closed-projects';

            const params = new URLSearchParams();
            params.append('page', this.page);

            const url = new URL(window.location.href);
            filters = filters || url.searchParams.getAll('filter[]');

            if (filters.length) {

                for (const filter of filters) {
                    params.append('filter[]', filter);
                }

            }

            apiUrl = `${apiUrl}?${params}`;

            controller = new AbortController();

            axios.get(apiUrl, { signal: controller.signal })
                .then(response => {
                    this.isUpdating = false;
                    if (response.data.length === 0) {
                        this.fullyLoaded = true;
                    } else {
                        this.closedProjectsMarkup.push(response.data);
                    }
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    },

    created () {
        this.closedProjectsMarkup.push(document.querySelector('.closed-projects').innerHTML);

        App.dispatcher.listen('closedProjectsFilterChange', data => {
            this.update(data.filters);
        })
    }
}
</script>

<style scoped>
.spinner {
    margin-top: 0;
    margin-bottom: 140px;
}
</style>

