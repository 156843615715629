<template>
    <div>
        <div class="search">
            <div class="row">
                <div class="col-md-4 col-lg-3 offset-md-1 offset-lg-2">
                    <div class="info">
                        <p>{{ translate('glossary_search_info') }}</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="input">
                        <input type="text" :placeholder="translate('search_term')" v-model="searchTerm">
                    </div>
                </div>
                <div class="col-md-2 col-lg-2" v-if="searchActive">
                    <div class="clear">
                        <button @click="clearSearch">{{ translate('reset') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10 col-lg-6 offset-sm-1 offset-lg-3">
                <div class="categories">
                    <div class="row">
                        <div class="col-2 col-md-1" v-for="(category, key) of categories" :key="key">
                            <div class="category">
                                <a :href="`#glossary-category-${category}`" v-if="categoryHasItems(category)">{{ category }}</a>
                                <span v-else>{{ category }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="categoriesWithItems.length">
            <div class="col-sm-10 col-lg-6 offset-sm-1 offset-lg-3">
                <div class="list">
                    <div :id="`glossary-category-${category}`" class="category" v-for="(categoryItems, category) in itemsPerCategory" :key="category">
                        <div class="name">
                            <h2>{{ category }}</h2>
                        </div>
                        <div class="items">
                            <div class="item" v-for="(item, key) of categoryItems" :key="key">
                                <div class="title">
                                    <h3>{{ item.title }}</h3>
                                </div>
                                <div class="content">
                                    <p v-html="item.content"></p>
                                </div>
                            </div>
                        </div>
                        <div class="back-to-top">
                            <a href="#glossary">{{ translate('back_to_top') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Xhr from '../core/xhr';

export default {
    data () {
        return {
            categories: [
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
                'Y', 'Z'
            ],
            items: [],
            searchTerm: ''
        }
    },

    computed: {
        itemsPerCategory () {
            const itemsPerCategory = {}

            for (const category of this.categories) {
                for (const item of this.items) {
                    const itemCategory = this.generateItemCategory(item.title)

                    if (
                        itemCategory === category &&
                        this.itemMatchesSearch(item)
                    ) {
                        if (!(category in itemsPerCategory)) {
                            itemsPerCategory[category] = []
                        }

                        itemsPerCategory[category].push(item)
                    }
                }

                if (category in itemsPerCategory) {
                    itemsPerCategory[category].sort(
                        (a, b) => (a.title > b.title) ? 1 : -1
                    )
                }
            }

            return itemsPerCategory
        },

        categoriesWithItems () {
            const categoriesWithItems = []

            for (const category of this.categories) {
                for (const item of this.items) {
                    const itemCategory = this.generateItemCategory(item.title)

                    if (
                        itemCategory === category &&
                        this.itemMatchesSearch(item)
                    ) {
                        categoriesWithItems.push(category)
                        break
                    }
                }
            }

            return categoriesWithItems
        },

        searchActive () {
            return this.searchTerm.trim() !== ''
        },

        itemsMatchingSearch () {
            if (!this.searchActive) {
                return this.items
            }

            const searchTerm = this.searchTerm.trim().toLowerCase()

            const items = []

            for (const item of this.items) {
                if (item.title.trim().toLowerCase().includes(searchTerm)) {
                    items.push(item)
                    continue
                }

                if (item.content.trim().toLowerCase().includes(searchTerm)) {
                    items.push(item)
                    continue
                }
            }

            return items
        }
    },

    methods: {
        fetchItems () {
            new Xhr().get('/api/glossary-items')
                .then(response => {
                    this.items = response
                })
                .catch(() => {
                    console.error('Error loading glossary items.')
                })
        },

        generateItemCategory (title) {
            const combining = /[\u0300-\u036F]/g;

            // We replace umlauts with plain ASCII characters for the
            // category selection
            // See https://stackoverflow.com/a/23633850
            return title
                .charAt(0)
                .toUpperCase()
                .normalize('NFKD')
                .replace(combining, '')
        },

        categoryHasItems (category) {
            return this.categoriesWithItems.includes(category)
        },

        itemMatchesSearch (item) {
            const found = this.itemsMatchingSearch.find(
                searchItem => searchItem.id === item.id
            )

            return typeof found !== 'undefined'
        },

        clearSearch () {
            this.searchTerm = ''
        }
    },

    created () {
        this.fetchItems()
    }
}
</script>
